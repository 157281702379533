<template>
<div>
    <div class="display-block">
        <alert-message v-if="msg" :error="msg"></alert-message>
        <page-header v-model="search" title="Brands" permission="createBrand" @input="updateApiCall(apiCall,search)" @click="navigateTo('/app/brands/0')"></page-header>
        <v-flex text-right>
            <import-file-button importData='brands'></import-file-button>
        </v-flex>
    </div>
    <div>
        <v-data-table :headers="headers" :items="items" class="elevation-1" :search="search" hide-default-footer :items-per-page="items.length">
            <template v-slot:item.active="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="item.active" v-bind="attrs" v-on="on" class="active-icon">mdi-check-circle-outline</v-icon>
                    </template>
                    <span>Enabled</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="!item.active" v-bind="attrs" v-on="on" class="deactive-icon">mdi-cancel</v-icon>
                    </template>
                    <span>Disabled</span>
                </v-tooltip>
            </template>
            <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <template v-for="icon in icons">
                    <action-button :key="icon.name" :icon="icon.name" :show="isAllowed(icon.permission)" @click="icon.name=='mdi-delete'?deleteBrand(item._id):$router.push(icon.path+''+item._id)"></action-button>
                </template>
            </template>
            <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.xsOnly">
                <brand-card :brand="item" :icons="icons" @delete="removeItem"></brand-card>
            </template>
        </v-data-table>
        <pagination ref="pagination" :api="apiCall" v-model="items" sort="+title"></pagination>
    </div>
    <alert-message v-if="msg" :error="msg"></alert-message>
</div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import BrandCard from '@/components/BrandResponsiveCard'
import ImportFileButton from '@/components/ImportFileButton'
export default {
    components: {
        ActionButton,
        BrandCard,
        ImportFileButton
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "Title",
                    value: "title"
                },
                {
                    text: "Active",
                    value: 'active'
                },
                {
                    text: 'Actions',
                    value: 'action',
                    align: 'right'
                }
            ],
            search: '',
            items: [],
            icons: [{
                    name: "mdi-eye",
                    path: "/app/brands/view/",
                    permission: "viewBrand"
                }, {
                    name: "mdi-pencil",
                    path: "/app/brands/",
                    permission: "editBrand"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteBrand"
                }
            ],
            apiCall:appConstants.BRANDS_API
        };
    },
    methods: {
        async deleteBrand(id) {
            try {
                await this.deleteItem("Are you sure you want to delete this Brand?", appConstants.BRANDS_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        },
        removeItem(id) {
            this.deleteBrand(id)
        }
    }
};
</script>

<style scoped>
.format {
    padding: 1%;
}
</style>
